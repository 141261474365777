<template>
	<el-form :label-width="$root.rwd < 3 ? '130px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<el-form-item label="帳號" class="underline">
			{{data.account}}
		</el-form-item>
		<el-form-item label="密碼">
			<router-link to="/change-password">
				<el-button type="info" size="small">修改密碼</el-button>
			</router-link>
		</el-form-item>
		<el-form-item label="信箱" class="underline">
			{{data.email}}
		</el-form-item>
		<el-form-item label="姓名" class="underline">
			{{data.name}}{{"\u3000"}}<small class="theme_text">{{data.gender}}</small>
		</el-form-item>
		<template v-if="visible('member')">
			<el-form-item label="出生年" class="underline">
				{{data.birthday | yearFilter}}
			</el-form-item>
			<el-form-item label="手機號碼" class="underline">
				{{data.cellphone}}
			</el-form-item>
			<el-form-item label="聯絡電話" class="underline">
				{{data.phone}}
			</el-form-item>
			<el-form-item label="通訊地址" class="underline">
				{{data.zipcode}}{{"\xa0"}}{{data.city}}{{data.district}}{{data.address}}
			</el-form-item>
		</template>
		<template v-if="visible('donor')">
			<h2 class="theme_text">椅友會員</h2>
			<hr>
			<el-form-item label-width="0">
				<b class="theme_text">年度捐款1000元(含)以上。您可選擇⼀次贊助１年或一次贊助２年以上。</b>
			</el-form-item>
			<el-form-item label="抬頭" class="underline">
				{{data.title}}
			</el-form-item>
			<el-form-item label="身分證／統編" class="underline">
				{{data.identity}}
			</el-form-item>
			<el-form-item label-width="0">
				<b class="theme_text">捐款收據可作為年度申報所得稅列舉扣除之憑證{{"\u3000"}}敬請費心填寫。</b>
			</el-form-item>
			<el-form-item label="年度捐款金額" class="underline">
				年度捐款金額：<small class="theme_text"><b>NT${{"\u3000"}}</b></small>{{(data.amount_per_year || 0) | numberFilter}}
				<br>共捐助：{{data.donation_years || 0}}<small class="theme_text"><b>{{"\u3000"}}年</b></small>
				<br>捐款⾦額總計：<b class="theme_text">NT${{"\u3000"}}{{(data.amount_per_year && data.donation_years && data.amount_per_year * data.donation_years || 0) | numberFilter}}</b>
			</el-form-item>
			<el-form-item label="郵寄地址" class="underline">
				{{data.recipient_zipcode}}{{"\xa0"}}{{data.recipient_city}}{{data.recipient_district}}{{data.recipient_address}}
			</el-form-item>
		</template>
		<template v-if="visible('volunteer')">
			<h2 class="theme_text">志工團</h2>
			<hr>
			<el-form-item label="綽號" class="underline">
				{{data.nickname}}
			</el-form-item>
			<el-form-item label="飲食習慣" class="underline">
				{{data.diet}}
			</el-form-item>
			<el-form-item label="最高學歷" class="underline">
				{{data.education}}
			</el-form-item>
			<el-form-item label="服務單位／就讀學校" class="underline">
				{{data.unit}}
			</el-form-item>
			<el-form-item label="社團、工作或志工服務經驗" class="underline">
				<pre>{{data.volunteer_experience}}</pre>
			</el-form-item>
			<el-form-item label="個人專長" class="underline">
				<pre>{{data.personal_expertise}}</pre>
			</el-form-item>
			<el-form-item label="願意參與志工類型（可複選）" class="underline">
				<ol>
					<li v-for="(item, i) in data.volunteer_type" :key="i">{{item}}</li>
				</ol>
			</el-form-item>
			<el-form-item label="可服務區域（可複選）" class="underline">
				<ol>
					<li v-for="(item, i) in data.volunteer_region" :key="i">{{item}}</li>
				</ol>
			</el-form-item>
		</template>
		<template v-if="visible('member')">
			<el-form-item label="是否訂閱電子報">
				<el-tag v-if="data.subscribe" type="success">是</el-tag>
				<el-tag v-else type="info">否</el-tag>
			</el-form-item>
		</template>
	</el-form>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState("auth", ["_auth"]),
		data() {
			return this._auth || new Object;
		}
	},
	methods: {
		visible(role) {
			return this._auth && this._auth.roles.includes(role);
		},
	}
}
</script>